<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <v-row class="auth-row ma-0">
        <v-col md="4" lg="4" xl="3" class="d-none d-md-block position-relative overflow-hidden pa-0">
          <div class="auth-illustrator-wrapper d-flex justify-center">
            <div class="d-flex align-center px-10 px-lg-12 flex-column justify-center flex-grow-1">
              <v-img
                contain
                max-width="453px"
                width="100%"
                class="flex-grow-0 mb-5"
                :src="require(`@/assets/images/login/login_illustration_experts.svg`)"
              ></v-img>
              <p class="text-center accent--text text-xl">bAV Portal</p>
            </div>
          </div>
        </v-col>
        <v-col md="8" lg="8" xl="9" cols="12" class="d-flex auth-bg-v2 pb-0 flex-column">
          <div class="d-flex flex-column">
            <AppBarI18n />
          </div>
          <div class="d-flex flex-grow-1 align-center">
            <v-card class="auth-card" color="transparent">
              <v-card-title class="d-flex align-center justify-center py-7">
                <v-img :src="appLogo" max-width="375px" alt="logo" contain></v-img>
              </v-card-title>
              <v-card-text>
                <p class="font-weight-bold text--secondary mb-3 text-left text-xl">
                  {{ $t('aut2faSetupHeading') }}
                </p>
                <p v-if="timeLeft === 0" class="text-sm mb-0 error--text">
                  {{ $t('auth2faSetupExpired1') }}
                  {{ $t('auth2faSetupExpired2') }}
                </p>
                <p v-else class="text-sm mb-0">
                  {{ $t('auth2faSetupInstructions1') }}.
                  {{ $t('auth2faSetupInstructions2') }}
                  <strong>{{ timeLeft }} </strong>{{ $t('auth2faSetupInstructions3') }}
                </p>
              </v-card-text>

              <!-- 2nd step -->

              <v-card-text>
                <v-form @submit.prevent="confirm">
                  <v-text-field
                    v-model="code"
                    outlined
                    :error="invalidCode"
                    :type="isPasswordVisible ? 'text' : 'password'"
                    :label="$t('auth2faConfirmCodePlaceholder')"
                    :placeholder="$t('auth2faConfirmCodePlaceholder')"
                    :append-icon="isPasswordVisible ? icons.mdiEye : icons.mdiEyeOutline"
                    class="required"
                    hide-details="auto"
                    @click:append="isPasswordVisible = !isPasswordVisible"
                  ></v-text-field>
                  <div class="d-flex align-center justify-end flex-wrap">
                    <a v-if="timeLeft === 0" href="" class="mt-1" @click.prevent="resendCode">{{
                      $t('auth2faSendCode')
                    }}</a>
                  </div>
                  <v-btn depressed block :disabled="!code" color="primary" type="submit" class="mt-3 mb-0">
                    {{ $t('buttonConfirm') }}
                  </v-btn>
                </v-form>
              </v-card-text>
              <v-card-text>
                <div class="d-flex justify-center">
                  <a href="" @click.prevent="$router.go(-1)">{{ $t('auth2faBackToLogin') }}</a>
                </div>
              </v-card-text>

              <v-card-text class="text-center flex-wrap mt-1 mb-3">
                <span class="mb-2 text-sm"> {{ $t('twoFactorProblem') }} </span><br />
                <span>
                  <button
                    :disabled="!acceptedCookies"
                    class="support-link primary--text text-sm mt-1"
                    @click.prevent="openUserlike"
                  >
                    {{ $t('loginSupportLink') }}
                  </button>
                </span>
              </v-card-text>
            </v-card>
          </div>
          <Footer />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, getCurrentInstance, watch, onMounted, onBeforeUnmount } from '@vue/composition-api';
import { required, phoneNumberValidator } from '@core/utils/validation';
import { mdiEyeOutline, mdiEyeOffOutline, mdiEye } from '@mdi/js';
import themeConfig from '@themeConfig';
import { sendAuthCode } from '../../api/fusion-auth';
import { LOGIN_TWO_FACTOR } from '../../store/modules/auth/actions';
import ModalWindow from '../../components/modal/ModalWindow';
import Footer from '@/components/footer/Footer.vue';
import AppBarI18n from '@/layouts/components/app-bar/AppBarI18nLoggetOut.vue';

export default {
  components: { ModalWindow, Footer, AppBarI18n },
  setup() {
    const vm = getCurrentInstance().proxy;

    const twoFactorForm = ref(null);
    const timeLeft = ref(60);
    const code = ref('');
    const timer = ref(null);
    const codeSent = ref(false);
    const isPasswordVisible = ref(false);
    const invalidCode = ref(false);
    const success = ref(false);
    const acceptedCookies = ref(vm.$store.getters.necessaryCookies);
    const openUserlike = () => {
      vm.$userlike.userlikeStartChat();
    };
    const decreaseTimeLeft = () => {
      if (timeLeft.value === 0) {
        clearInterval(timer.value);
      } else {
        timeLeft.value -= 1;
      }
    };

    const resendCode = () => {
      timeLeft.value = 60;
      send2faAuthCode();
    };

    const send2faAuthCode = () => {
      invalidCode.value = false;

      const { twoFactorId } = vm.$store.state.auth;
      const methodId = vm.$store.state.auth.twoFactorMethod.id;

      if (timeLeft.value === 0 || !twoFactorId || !methodId) return;

      clearInterval(timer.value);
      codeSent.value = true;
      timeLeft.value = 60;
      timer.value = setInterval(decreaseTimeLeft, 1000);

      sendAuthCode(twoFactorId, methodId);
    };

    const confirm = () => {
      invalidCode.value = false;
      vm.$store
        .dispatch(LOGIN_TWO_FACTOR, {
          twoFactorId: vm.$store.state.auth.twoFactorId,
          code: code.value,
        })
        .catch(() => (invalidCode.value = true));
    };

    watch(
      () => vm.$store.state.app.currentLang,
      () => {
        if (!codeSent.value) twoFactorForm.value.reset();
      },
    );

    onMounted(() => {
      send2faAuthCode();
    });

    onBeforeUnmount(() => {
      clearInterval(timer.value);
      timer.value = null;
    });

    return {
      code,
      isPasswordVisible,
      timeLeft,
      timer,
      twoFactorForm,
      codeSent,
      success,
      invalidCode,
      decreaseTimeLeft,
      resendCode,
      confirm,
      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiEye,
      },
      validators: {
        required,
        phoneNumberValidator,
      },
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
      acceptedCookies,
      openUserlike,
    };
  },
};
</script>

<style lang="scss" scoped></style>
